
.avatarComp{
  display: inline-block;
  .avatarComp{
    &-empName, &-empPosition{
      margin: 0;
    }
    &-empName{
      font-size: 1.1rem;
      font-weight: bold;
      margin-top: 5px;
    }
  }
}