$sidebar-cutter: 200px;
$small-cutter: 15px;
$toolbar-card: 120px;
$font-12px: 0.75rem;
$gray: #8f8f8f;

.app-layout {
  min-height: 100vh;
  direction: ltr;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  width: 100%;


  .ant-input-number-handler-wrap {
    display: none;
  }

  .select-options {
    color: black !important;
  }

  &.ant-layout-has-sider {
    .sidebar {
      padding: $small-cutter;
      z-index: 3;

      &-radar-chart .radar-chart-popover {
        color: #fff;

        // display: flex;
        // align-items: flex-start;
        // font-size: 12px;
        // margin-bottom: 10px;
        p {
          text-align: center;
          margin-bottom: 0;
          margin-top: 1em;
        }

        .radar-icon {
          margin-right: 5px;
          width: 15px;
        }
      }

      &-chart {
        overflow: auto;
        //max-height: calc(100vh - 330px);

        .radar_title {
          position: relative;
          color: #fff;
          margin: 0;
          font-size: 10px;
          position: relative;
        }

        &-image {
          margin-bottom: 20px;
        }
      }

      &-right {
        .ant-layout-sider-children {
          overflow: auto;
        }
      }

      &.ant-layout-sider-zero-width {
        padding: 0;
      }

      &-emp {

        &-name,
        &-position {
          color: #fff;
        }
      }

      &.ant-layout-sider-collapsed {
        .ant-menu-inline-collapsed {
          margin-top: 20px;
        }

        .ant-menu-item {
          padding: 0px calc(50% - 10px);

          // padding-top: 0px;
          // padding-right: calc(50% - 8px);
          // padding-bottom: 0px;
          // padding-left: calc(50% - 8px);
          .ant-menu-item-icon {
            min-width: 20px;
          }
        }
      }

      .ant-menu-item {
        background-color: #414141;
        border-radius: 6px;
      }

      .ant-menu-title-content {
        font-size: 1rem;
      }

      &-chart-text {
        color: #fff;
        margin-top: 20px;
      }

      .footer-menu {
        top: 0;
        right: 0;
        background: #010101;
        width: 100%;
        //padding-bottom: 15px;
        padding-top: 0px;
        // display: flex;
        // align-self: flex-end;
        // justify-content: center;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        // background: #010101;
        // width: 100%;
        // padding-bottom: 15px;
        // padding-top: 15px;

        // .sidebar-logo {
        //   min-width: 145px;
        //   text-align: center;

        //   .sidebar-collapse {
        //     height: 30px;
        //   }
        // }
      }

      .sidebar-collapse {
        &-right {
          position: fixed;
          right: 2px;
          bottom: 0;
          left: auto;
          background: #010101;
        }
      }

      hr {
        width: 150px;
        border-color: #848484;
      }
    }

    .site-layout {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  // Header Style
  .header-container {
    padding: 0.75rem;
    align-items: center;
    margin-right: -10px;
    margin-left: -10px;
    border: 0;
    margin-bottom: 15px;
    padding-left: 20px;

    .ant-col.ant-col-12 {
      padding: 0px 15px;
    }

    .ant-col.ant-col-12.serchCol>* {
      margin-inline: 10px;
    }

    .ant-switch-inner {
      min-width: max-content;
    }
  }

  h2.ant-typography {
    margin: 0;
  }

  // general content style
  .ant-layout-content {
    padding-left: $small-cutter;
    padding-right: $small-cutter;
    overflow: auto;
  }

  // ToolBar Style
  .form-card {
    .ant-radio-group-solid {
      overflow: auto;
      padding-bottom: 10px;
      width: 100%;
      margin-bottom: 5px;
    }

    .ant-radio-button-wrapper {
      height: 100%;
      padding: 5px;
      width: 100%;
      border: 0;
      box-shadow: 2px 2px 3px 0px #cccccc;

      span {
        width: 100%;
        display: block;
      }
    }

    .ant-space-item {
      min-width: $toolbar-card;
      width: 12%;
    }

    .card-item {
      &.current-tool {
        border: 1px solid #1db71d;

        p {
          color: #1db71d;
          font-weight: bolder;
        }
      }

      span:not(.ant-radio-button):not(.ant-progress-text) {
        display: flex;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        p {
          width: 65%;
          min-height: 55px;
          line-height: normal;
          margin: 0;
          font-weight: bold;
          font-size: $font-12px;
        }

        .ant-progress {
          width: 35%;

          .ant-progress-text {
            font-size: 0.9em;
          }
        }
      }

      // .ant-radio-button-checked,
      // .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      //   background: #f8f8f8;

      //   +span {
      //     p {
      //       color: #1644B0 !important;
      //     }

      //   }
      // }
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: 2px 2px 3px 0px rgb(204 204 204 / 83%) !important;
      background: #fff;
    }
  }

  .verification-list .form-card {
    min-width: 100%;

    .ant-space {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }

    .list-tools {
      .ant-space-item {
        min-width: 128px;
      }
    }
  }

  .ant-layout-footer {
    margin-top: 5px;
    padding: 5px 20px;
  }

  .header-icon {
    text-align: center;

    svg {
      vertical-align: middle;
    }
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: rgb(255, 255, 255) !important;
  border-color: rgb(249, 249, 249) !important;
}

// custom scroll

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 2px transparent;
  border-radius: 10px;
  border: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

:hover::-webkit-scrollbar-thumb {
  background: #989898;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989898;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

// Tabs design
.ant-tabs.ant-tabs-top .ant-tabs-nav {
  margin: 0;
}

.ant-tabs.ant-tabs-top .ant-tabs-content-holder {
  // border: 1px solid #f0f0f0;
  padding-bottom: 15px;
  border-top: 0;
  padding: 15px;
}

// general input style
.ant-form-item-control-input-content .ant-input-number,
.ant-form-item-control-input-content .ant-input-number-group-addon {

  // background-color: transparent;
  // border-color: #808080;
  :focus {
    border-color: #5070f2 !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 0 0 2px rgba(17, 132, 226, 1) !important;
    box-shadow: 0 0 0 2px rgba(17, 132, 226, 1) !important;
  }
}

.ant-input-affix-wrapper-focused {
  border-color: #5070f2 !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(17, 132, 226, 1) !important;
  box-shadow: 0 0 0 2px rgba(17, 132, 226, 1) !important;
}

// general classes
.scroll-100vh-300 {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.celery {
  background-color: #93c452;
}

.eucalyptus {
  background-color: #2fab58;
}

.denim {
  background-color: #1576bd;
}

.thunderbird {
  background-color: #c4221f;
}

.rhino {
  background-color: #293560;
}

// General font colors and size

.popover-content {
  background: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-top: 10px;

  .chartTitle {
    font-size: 12px;
  }

  .chartType {
    font-size: 8px;
  }
}

.ant-popover-inner-content {
  .barChart_container_waterfull {
    width: 75vh;
  }

  .barChart_container_rowstack {
    width: 75vh;
    height: auto;
  }
}

// .popover-ebit-potential{
//   .ant-popover-content {
//     width: 100vh;

//     .ant-popover-inner-content {
//       padding: 12px;
//     }
//   }
// }

// .ant-popover-content {
//   width: 100vh;
//   .ant-popover-inner-content {
//     padding: 12px;
//   }

//   .radar_container {
//     background: #000;
//     padding: 15px 15px 0 15px;
//     position: relative;
//   }

//   .radar_title {
//     margin-bottom: 0;
//     color: #999;
//     font-size: 25px;
//     position: absolute;
//     width: calc(100% - 30px);
//   }
// }

.sidebar-radar-chart {
  background: #000;
}

td input,
td .ant-input-number,
.subquestion-input .ant-input-number {
  // min-width: 100%;
  width: 100%;
}

/* Admin components */
.admin-component {
  .ant-table th.ant-table-cell {
    font-weight: 700;
  }

  .ant-table .ant-table-cell {
    padding: 6px;
    text-align: center;

    .ant-space {
      justify-content: center;
    }

    .ant-form-item {
      margin-bottom: 4px;
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-between;
  }

  .ant-tabs-tab {
    display: block;
    width: 100%;
    text-align: center;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab {
    font-weight: bold;
  }

  .ant-btn-link {
    padding: 0px;
  }

  table {
    max-width: calc(100vw - 250px);
  }
}

// .parent-image img{
//   width: 30%;
//   height: auto;
// }