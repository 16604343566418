.App {
  text-align: center; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ant-drawer-content-wrapper{
  width: 80% !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#content-section {
  height: 500px;
  width: 500px;
  margin: 10%;
}
.ant-select-dropdown-hidden{
  z-index: 9999 !important;
  top: 30px;
  /* display: block !important; */
}
/* 
svg.recharts-surface {
  position: absolute;
}

.recharts-polar-grid {
  position: absolute;
  z-index: 9;
  stroke: white;
}

.recharts-polar-grid-angle {
  z-index: 99999;
  position: fixed;
  stroke: white;
}
.recharts-polar-grid-angle>line {
  z-index: 99999;
  position: fixed;
  stroke: white;
}

.recharts-polar-grid-concentric {
  z-index: 9999;
  position: fixed;
  stroke: white;
}

.recharts-polar-grid-angle {
  z-index: 999;
  position: fixed;
  stroke: white;
}

.recharts-polar-grid-concentric {
  z-index: 99;
  position: fixed;
  stroke: white;
}
path.recharts-polar-grid-concentric-polygon:nth-child(5){
  fill: gray;
  fill-opacity: 1;
  stroke: white;
  z-index: 10;
  position: fixed;
}
path.recharts-polar-grid-concentric-polygon:nth-child(6) {
  fill: gray;
  fill-opacity: 1;
  stroke: white;
  z-index: 5;
  position: fixed;
} */

/* path.recharts-polar-grid-concentric-polygon:nth-child(2){
  fill: #808080;
  fill-opacity: 0.9;
  d: path("M 250,54L 419.74097914175,152L 419.74097914175,348L 250,446L 80.25902085825001,348L 80.25902085825004,151.99999999999997Z");
}
path.recharts-polar-grid-concentric-polygon:nth-child(3){
  d: path("M 250,103L 377.3057343563125,176.5L 377.3057343563125,323.5L 250,397L 122.69426564368752,323.5L 122.69426564368753,176.5Z");
}
path.recharts-polar-grid-concentric-polygon:nth-child(4){
  d: path("M 250,152L 334.870489570875,201L 334.870489570875,299L 250,348L 165.129510429125,299L 165.12951042912502,201Z");
}
path.recharts-polar-grid-concentric-polygon:nth-child(5){
  d: path("M 250,250L 250,250L 250,250L 250,250L 250,250L 250,250Z");
}
  */
  .recharts-polar-angle-axis > path{
    fill: #808080;
  }
  .general-spin{
    display: flex;
    justify-content: center;
    height: 100%;
    min-height: 100%;
    align-items: center
  }
  #root{
    height: 100%;
    min-height: 100%;
  }
  /* for svg with span in button */
  .ant-btn > svg+span{
    float: right;
  }
  .evaluation-panel .ant-collapse .ant-collapse-item span.ant-collapse-header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }