.All-charts-Btn_div {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 12px;
}

.radar-chart-popover {
  p {
    color: white;
    text-align: center;
  }
}