.RTDCurve-ResponsiveContainer {
    .Tooltip {
        height: 100px;
        overflow: scroll;
    }
    .recharts-tooltip-item {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        font-size: 8px;
    }

    .recharts-tooltip-label {
        font-size: 10px;
    }

    .recharts-default-tooltip {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}
