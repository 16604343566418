#self-assessement-tab{ 
    .ant-space-item {
        width: auto;
      }

      .text-area-space-compact{
        border-radius: 0px 4px 4px 0px;
      }
      .ant-table-thead > tr > th{
        padding: 12px 2px;
      }
      .sa-radar-main-div{
        width: min-content;
      #sa-radar-main-div{
        padding-top: 5px;
        padding-right: 5px;
      }
      }

}
