// model-admin
.admin-model {
  .ant-modal-title {
    text-decoration: solid;
    font-weight: 700;
    font-size: 16px;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-input-number-affix-wrapper {
    width: initial;
    padding-inline-start: initial;
  }
}
.app-admin-layout {
  h2.ant-typography {
    text-align: center;
    margin-top: 0.5em;
  }
}
