.save-flproject-saveBtn-container {
    display: inline-block;
}

// .flprject-date-pickers {
//     display: flex;
//     width: 100%;
//     text-align: center;
//     justify-content: space-between;
// }
.add-edit-form{
    margin-top: 20px;
}
