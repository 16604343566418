.comparison_tab_main_div, .RTDCurve_tab_main_div {
    .ant-table-cell {
        font-size: 12px;
        padding: 0px;
        height: 30px;
    }

    .ant-table-tbody {
        tr.ant-table-row.ant-table-row-level-0 td {
            padding: 0px 0px;
        }
    }
}
// .comparison_tab_main_div {
//     display: flex;
//     text-align: center;
//     justify-content: space-between;
// }
.alert_body {
    padding: 20px;
    display: flex;
    text-align: center;
}
.warning_title {
    font-size: 60px;
    font-weight: 800;
    color: red;
    margin-right: 20px;
}

.warning_text {
    font-size: medium;
    font-weight: 500;
    color: black;
    display: flex;
    text-align: center;
}
.warning_text_value {
    align-self: center;
}

.DepartmentSpansDiv {
    padding-left: 5px;
    position: relative;
}

.DepartmentOrderSpan {
    font-weight: 800;
}

.DepartmentNameSpan {
    font-weight: 400;
    margin-left: 5px;
}
.peer_flProject_warning_div {
    display: flex;
    text-align: center;
    justify-content: center;
}
// .peer_flProject_warning_span {
//     align-self: center;
//     text-align: left;
//     margin-top: 20px;
//     padding-left: 5px;
//     padding-right: 20px;
//     padding-top: 17px;
//     padding-bottom: 20px;
// }
// .current_flproject_div {
//     width: 50vh;
//     padding: 10px;
//     // background-color: white;
// }

.comparative_flproject_div {
    width: 50vh;
    padding: 10px;
    // background-color: #eef3ff;
}
// .figures_div {
//     width: 100%;
// }
// .figures_div {
//     width: 100%;
// }
.current_flproject_figure_div {
    width: 100%;
    height: 40vh;
    padding: 10px;
    // background-color: white;
}
.peer_flProject_figure_div{
    width: 100%;
    height: 40vh;
    padding: 10px;
    //background-color: #eef3ff;
}
.comparative_flproject_figure_div {
    width: 100%;
    height: 40vh;
    padding: 10px;
    //background-color: #eef3ff;
}
