$sidebar-cutter: 210px;
$small-cutter: 15px;
$toolbar-card: 120px;
$font-12px: 0.75rem;
.ant-input-number-handler-wrap {
  display: none;
}
.app-admin-layout {
  min-height: 100vh;
  direction: ltr;
  height: 100vh;
  position: fixed;
  width: 100%;

  .ant-layout-content {
    height: 100vh;
    padding: 20px;
    overflow: auto;
  }
  .header-container {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    border: 0;
  } 
  .ant-layout-footer {
    margin-top: 5px;
    padding: 5px 20px;
  }
  .sidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;

    &-right {
      right: 0;
      left: auto;
    }

    &-emp {
      &-name,
      &-position {
        color: #fff;
      }
    }

    // .ant-menu-item {
    //   background-color: #5d5d5d;
    //   border-radius: 6px;
    // }

    .ant-menu-title-content {
      font-size: 1rem;
    }

    &-chart {
      background-color: #fff;
    }
  }

  .site-layout {
    margin-left: $sidebar-cutter;
    //margin-right: $sidebar-cutter;
  }

  .ant-menu,
  .ant-menu-item,
  .ant-menu-submenu {
    margin: 0 !important;
  }

  .ant-menu-submenu-open {
    .ant-menu-item {
      padding-left: 24px !important;
    }
  }

  tbody {
    & > tr {
      & > td {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}
