.AllChartsTopBar-container{
    border-bottom: 1px solid #ccc;
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin-bottom: 10px;
   padding: 2px 5px;
//    background-color: #f3f3f3;
}

.downloadall-div{
    margin-right: 10px;
}

.checkall-div{
    display: flex;
    text-align: center;
}

.download-checkall_div{
    display: flex;
    text-align: center;

}

.return-back-div{
    display: flex;
    text-align: center;
}