.edit_levers_ctrl_div {
  margin-top: 30px;
  margin-bottom: 20px;
}

.edit_levers_ctrl_div {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.edit_levers_btns_div {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.edit_levers_reset_btn_div {
  margin-right: 10px;
}

.edit_levers_reset_btn {
  background-color: #7a7a7a !important;
  color: white !important;
}

.edit_levers_save_btn {
  background-color: #1644b0 !important;
  color: white !important;
}
