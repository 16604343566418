.profile_version {
  .ant-tabs-left > .ant-tabs-content-holder {
    background-color: rgba(177, 173, 186, 0.24);
  }
  .ant-tabs-tab-active {
    .ant-card {
      background-color: rgba(177, 173, 186, 0.24);
      background-attachment: transparent;
    }
    .ant-btn.ant-btn-default {
      background-color: rgba(177, 173, 186, 0.24);
    }
  }
  .anticon {
    font-size: 14px;
    cursor: pointer;
    margin-right: .2rem;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: transparent;
    width: 3px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 0 15px 15px 0px;
    margin: 0;
  }
  .ant-card-head {
    border-bottom: none;
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 5px;
  }
  .title_group {
    padding: 0.5rem 0;
    font-weight: 600;
    font-size: 20px;
    padding-left: 2rem;
    margin-bottom: 0.8rem;
  }
  .title_Active {
    padding: 0.3rem 0.7rem;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    color: unset;
    background-color: initial;
  }
  .card_tasks .ant-card-head-title .ant-tag {
    font-weight: 400;
    font-size: 14px;
    padding: 0.3rem 0.5rem;
  }
  .version-icon-off,
  .version-icon-on {
    padding: 1.5px;
    border-radius: 50%;
    color: #e0e0e1;
  }
  .version-icon-off {
    background-color: red;
  }
  .version-icon-on {
    background-color: green;
  }
  .ant-card-head-title span.ant-tag {
    float: right;
  }
  .general-model-component .ant-btn {
    margin-bottom: 0;
  }
  .group-item {
    display: flex;
    column-gap: 0.5rem;
    font-size: 0.8rem;
  }
  .group-item span:first-child {
    font-weight: 600;
  }
  .ant-tabs-content-holder {
    padding: 0.1rem !important;
  }
}
.profile_title_Active {
  padding: 0.5rem 0.7rem;
  margin-bottom: 1rem;
  color: #496dbf;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
  }
  .general-model-component .ant-btn-primary{
    margin-bottom: 0;
  }
}
#rc-tabs-0-panel-version_4676 .ant-tabs-nav-operations {
  display: none;
}

.table_map-tasks {
  th.ant-table-cell {
    padding: 3px;
    font-size: 10px;
  }
  .ant-input-number-affix-wrapper input {
    padding-left: 0.5rem;
  }
  .ant-input-number-affix-wrapper {
    padding-inline-start: 0%;
  }
}
