.frontloading-component {
    font-weight: bold;

    .ant-row > .ant-col-left {
        font-size: 1.5rem;
    }
    .ant-row > .ant-col-right {
        font-size: 1.5rem;
    }
    .ant-row > .ant-col-left h4{
        font-size: 1rem;
    }
    .ant-row > .ant-col-right h4{
        font-size: 1rem;
    }

    .center {
        text-align: center;
    }

    .phase {
        height: 75vh;
        padding: 24px;
        margin: 12px 48px;
        box-shadow: 0 4px 4px #00000040;
    }

    .phase-wrapper:nth-child(3n+1) .phase {
        background-color: #e2e2e268;
    }

    .phase-wrapper:nth-child(3n+2) .phase {
        background-color: #f7323268;
    }

    .phase-wrapper:nth-child(3n+3) .phase {
        background-color: #9268f868;
    }

    .ant-row {
        line-height: 2.75rem;
    }

    .ant-col-seperator {
        border-right: solid 2px black;
    }

    .ant-col-right {
        text-align: left;
        padding-left: 2rem;
    }

    .ant-col-left {
        text-align: right;
        padding-right: 2rem;
    }
}