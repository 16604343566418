.profile-cardbody {
  padding: 10px;


  tr.ant-table-expanded-row .ant-table-expanded-row-level-1 td:empty {
    display: none;
  }


  .col-data-review-activity {
    padding: 5px;
  }

  .voting-process-body {
    .ant-space-item {
      width: 100%;
    }
  }

  .voting-process-header {
    margin-bottom: 10px;

    .ant-space-item {
      width: 100%;
    }
  }

  .ia-process {
    .ant-descriptions-item-content {
      justify-content: center;
    }

    .ant-descriptions-item-label {
      font-weight: 700;
    }
  }

  .start_point_form {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;

    .start_point_btn {
      margin-top: .4rem;
    }
  }
}