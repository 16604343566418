.admin-operation {
  .anticon {
    font-size: 20px;
    cursor: pointer;
    &.anticon-edit {
      color: #4faaff;
    }
    &.anticon-delete {
      color: red;
    }
    &.anticon-stop {
      color: red;
    }
    &.anticon-check-circle {
      color: green;
    }
  }
}
