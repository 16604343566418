.menu_style {
  background-color: white !important;
  max-height: 500px !important;
  overflow-y: scroll;
}
.ant-popover .ant-popover-inner-content li {
  width: 100%;
}
.shown_tile_div {
  padding: 10px 0px;
  font-weight: 700;
  color: #37ac37;
  text-align: center;
}

.hidden_tile_div {
  padding: 10px 0px;
  font-weight: 700;
  color: rgb(230, 77, 77);
  text-align: center;
}

.columns-popover {
  max-height: 500px;
}

.col_options_collapse {
  max-height: 220px !important;
  overflow-y: scroll;
}

.coloption_class {
  padding-left: 15px !important;
}

.collapse-custom-panel{
  background-color: white !important;
  border: none !important;
}

.collapse-custom-GeneralProjectData-panel{
  background-color: white !important;
  border: none !important;
}

