.main-table-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}

.strategie-table-div {
    width: 100%;
}

.process-table-div {
    width: 100%;
}

.projectman-table-div {
    width: 100%;
}

.structure-table-div {
    width: 100%;
}

.people-table-div {
    width: 100%;
}

.topLevelChartsContainer {
    text-align: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.leverLevelCharts {
    display: -webkit-inline-box;
    display: -moz-inline-stack;
    text-align: center;
    justify-content: space-evenly;
    max-width: 100%;
    overflow: scroll;
}

.toplevelheatmap {
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
    overflow-y: scroll;
}

.stellhebel-strategie-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.process-projectmanager-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.structure-people-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}

.lever-first-sublever-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.second-third-sublever-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.fourth-fifth-sublever-div {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
}

.sublever-level-container {
    text-align: center;
    justify-content: center;
}
.return-tolever-btn {
    position: absolute;
    left: -27px;
}

.success-factor-level {
    display: flex;
}

.back-tosublever-btn-div {
    position: absolute;
    left: -27px;
    z-index: 100;
}

.back-tosuccessfactors-btn-div {
    position: absolute;
    z-index: 100;
    left: -27px;
}
.question-level {
    display: flex;
}

.questions-charts-table {
    overflow: scroll;
}

.success-factor-charts-table {
    overflow: scroll;
}

.top-level-six-charts {
    margin: auto;
}

.allChartsContainer {
    position: relative;
}

.stellhebellLevelChart {
    min-width: 502px;
    min-height: 255px;
}
.strategieChartDiv {
    min-width: 502px;
    min-height: 255px;
    cursor: pointer;
}
.processChartDiv {
    min-width: 502px;
    min-height: 255px;
}

.projectManChartDiv {
    min-width: 502px;
    min-height: 255px;
}
.structureChartDiv {
    min-width: 502px;
    min-height: 255px;
}
.peopleChartDiv {
    min-width: 502px;
    min-height: 255px;
}
