#sub-lever-section {
  overflow-x: scroll;

  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      .ant-collapse-header {
        align-items: center !important;
      }
    }
  }
}

.sub-question-div {
  .ant-input-number-disabled,
  .ant-input-number-disabled~.ant-input-number-group-addon {
    background-color: transparent;
  }
}

#lever-section {
  position: relative;

  .ant-breadcrumb {
    color: white;

    .ant-breadcrumb-separator {
      color: white;
    }

    li:last-child,
    li:last-child a {
      color: white;
    }
  }

  .BuildRoundedIcon_div {
    transform: rotate(45deg);
    padding: 0;
    margin: 0;
    display: inline-block;
    margin-right: 8px;
  }

  .table-actions-filter {
    margin: 5px 5px;
    float: right;
    //width: 50%;
    // position: absolute;
    // right: 0;
    // top: 0px;
    z-index: 1;
  }

  .ant-tabs-content-holder {
    padding: 0 20px 20px;
  }

  .ant-tabs-content-holder {
    // border: 1px solid #efeaea;
    border-top: 0;
  }

  .ant-tabs-bottom>.ant-tabs-nav,
  .ant-tabs-bottom>div>.ant-tabs-nav,
  .ant-tabs-top>.ant-tabs-nav,
  .ant-tabs-top>div>.ant-tabs-nav {
    margin: 0;
  }

  .ant-popover-content {
    width: 50vh;

    .ant-popover-inner-content {
      padding: 12px;
    }
  }
}

.subquestion-input {
  input {
    text-align: right !important;
  }
}

.subquestion-result {
  input {
    text-align: right !important;
  }
}

.levers-table {
  .kpi-value {
    font-weight: 700;
  }
  .ant-input-number-group-addon {
    padding: 0 3px;
    font-size: 12px;
  }
  .ant-table.ant-table-small .ant-table-tbody>tr {
    // >td.ant-table-cell-fix-left {
    //   padding-left: 25px;
    // }

    &.ant-table-row-level-1>td.ant-table-cell-fix-left {
      padding-left: 30px;
    }

    &.ant-table-row-level-2>td.ant-table-cell-fix-left {
      padding-left: 40px;
    }

    &.ant-table-row-level-3>td.ant-table-cell-fix-left {
      padding-left: 80px;
      padding-top: 0px;

      .indent-level-3 {
        display: none;
      }
    }

    // .ant-space-vertical {
    //   position: absolute;
    //   top: 10px;
    //   left: 10px;
    // }
  }

  .ant-list-item-action {
    svg {
      display: block;
    }
  }

  .ant-table-row-level-3 {

    //text-align: center;
    [data-icon="minus-circle"] {
      display: none;
    }

    // svg:not(.MuiSvgIcon-root) {
    //   display: none;
    // }

    .MuiSvgIcon-root {
      display: block;
    }
  }

  .one-lever-table {
    .ant-table.ant-table-small .ant-table-tbody>.ant-table-row-level-0.one-lever-row td {
      font-weight: bold;
      line-height: normal;
    }

    .ant-table.ant-table-small .ant-table-tbody>tr>td.ant-table-cell-fix-left {
      padding-left: 15px;
    }

    .ant-table-tbody>.ant-table-row-level-3.one-lever-row td {
      input {
        max-width: 100%;
      }
    }

    tr.ant-table-row.one-lever-row td,
    tr.one-lever-row th.ant-table-cell {
      border: 0;
      font-size: 0.75rem;
    }

    td.ant-table-cell-fix-left,
    th.ant-table-cell-fix-left-last {
      box-shadow: 0px -2px 8px rgb(125 124 124 / 26%);
      left: 0px !important;
    }

    .ant-table-header th {
      font-weight: bold;
      line-height: 1;
    }
  }

  .sub-question-row {
    margin: 2px !important;
  }

  .editable-table-dproject-lever {    
    margin-top: 2%;
    display: inline-flex;
  }

  ::-webkit-scrollbar {
    background: #b5b5b5;
  }

  :hover::-webkit-scrollbar {
    background: transparent;
  }

  .table-mode {
    margin-right: 10px;
  }

  .ant-select-selector {
    width: 100%;
    height: 25px !important;
    padding: 0px 12px;
    border-radius: 4px;
    opacity: 1;
    transition: all 0.3s ease 0s;

    .ant-select-selection-item {
      line-height: 25px;
    }
  }

  // .ant-table-body{
  //   overflow: auto scroll;
  //   max-height: calc(100vh - 310px);
  // }
}

.comments-on-levers {
  .ant-space-item {
    width: 100%;
  }

  .ant-list-item-action {
    text-align: center;
  }

  .ant-list-item-meta-title {
    label {
      margin-bottom: unset !important;
      font-size: 12px !important;
    }
  }

}

.columns-collection-role-filter-popover {
  .ant-menu-vertical {
    border: 0px;
  }
}

.columns-filter-popover {
  &.ant-popover {
    .ant-popover-inner-content {
      padding: 0;
      max-width: 70vh;

      p {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0;
        line-height: normal;
        padding-top: 10px;
      }

      ul .hidden-items,
      ul>div {
        display: flex;
        flex-wrap: wrap;
      }

      .hidden-items {
        min-height: 65px;
        .ant-menu-item.ant-menu-item-active{
          background-color: transparent !important;
        }
      }

      // li {
      //   width: 50%;
      // }
    }
  }

  .ant-popover-content .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
}

// .ant-tabs-dropdown-menu {
//   background-color: #efeaea;
// }
.ant-select-item-option-content {
  max-width: min-content;
}