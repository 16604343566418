.fullheatmap-container {
    padding: 10px;
    border: 1px solid #ccc;
    margin-top: 20px;
}

.heatmap_div {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;

    .inHeatMapCellBarChart {}

}

.outHeatMapCellBarChart {
    .barChart_container {
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .chartTitle_container {
            text-align: center;
            justify-content: center;

            .chartTitle {
                font-weight: 500;
            }

            .chartDescription {
                margin: auto;
                font-weight: 200;
                font-size: small;
                max-width: 70%;
                word-wrap: break-word;
            }

            .chartUnit {
                font-weight: 200;
                font-size: small;
            }

            .chart_container {}
        }
    }
}

.heatmap_div div {
    margin: 0px !important;
    padding: 0px !important;
}

.HeatMap-cell {
    border: 3px;
}

.chartControlsDiv {
    display: flex;
}

.saveControlDiv {
    margin-right: 5px;
}

.switchControlDiv {
    margin-right: 5px;
}

.To-charts {
    padding: 5px;
}

.To-serials {
    padding: 5px;
}

.levers_container {
    display: flex;
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-left: 2px solid black;
}

.strategie {
    display: flex;
    text-align: center;
    justify-content: center;
    border-right: 2px solid black;
}

.process {
    display: flex;
    text-align: center;
    justify-content: center;
    border-right: 2px solid black;
}

.project_management {
    display: flex;
    text-align: center;
    justify-content: center;
    border-right: 2px solid black;
}

.structure {
    display: flex;
    text-align: center;
    justify-content: center;
    border-right: 2px solid black;
}

.people {
    display: flex;
    text-align: center;
    justify-content: center;
}