.extra-tab-currentflproject{
    font-size: 0.8em;
    /* background-color: rgb(252, 248, 244); */
    padding: 5px;
}

.currentFlProject-Name-div{
    font-weight: bolder;
    width: 230px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.currentFlProject-startdate-span{
    font-weight: bolder;
}

.currentFlProject-enddate-span{
    font-weight: bolder;
}

.currentFlProject-lastphase-span{
    font-weight: bolder;
    width: 60px;
    display: contents;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
