.input_tab_main_div {
  display: flex;
  justify-content: space-between;
  .flprojectselection_card_div {
    width: 32%;
    .ant-card {
      padding: 0 0.7rem 0.5rem;
    }
  }
  .flprojectadd_card_div {
    width: 48%;
    .ant-card {
        padding: 0 0.7rem 0.5rem;
      }
  }
  .fl_project_form {
    width: 100% !important;
  }
  .ant-card-head {
    padding: 0px !important;
  }
  .flprojectadd_card_title {
    font-weight: 900;
  }
  .flprojectselection_card_title {
    font-weight: 900;
  }
  .empty-flprojects {
    margin-top: 20px;
  }
}
