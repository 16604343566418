.HeatMap-cell {
    .barChart_container {
        text-align: center;
        justify-content: center;
    }

    .chartTitle {
        display: block;
        font-weight: 500;
        font-size: 2px;
    }

    .small-chartTitle {
        display: block;
        font-weight: 500;
        font-size: small;
        max-width: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .chartDescription {
        display: block;
        font-weight: 200;
        font-size: small;

        max-width: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 2px;
    }

    .chartUnit {
        display: block;
        font-weight: 200;
        font-size: small;

        font-size: 2px;
    }
}

.ant-table-cell {
    .barChart_container {
        text-align: center;
        justify-content: center;
    }

    .chartTitle {
        font-weight: 500;
    }

    .small-chartTitle {
        font-weight: 500;
        font-size: small;

        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: auto;
    }

    .chartDescription {
        font-weight: 200;
        font-size: small;

        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: auto;
    }

    .chartUnit {
        font-weight: 200;
        font-size: small;
        margin: auto;
    }
}



.sidebar-chart {
    .barChart_container {
        text-align: center;
        justify-content: center;
    }

    .chartTitle {
        font-weight: 500;
    }

    .small-chartTitle {
        font-weight: 500;
        font-size: small;

        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: auto;
    }

    .chartDescription {
        font-weight: 200;
        font-size: small;

        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: auto;
    }

    .chartUnit {
        font-weight: 200;
        font-size: small;
        margin: auto;
    }
}



.topLevelChartsContainer {
    font-weight: 500;
    font-size: small;
    .barChart_container {
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .small-chartTitle {
        display: block;
        font-weight: 500;
        font-size: small;
    }

    .chartDescription {
        display: block;
        font-weight: 200;
        font-size: small;
    }
    .chart_container {
        display: contents;
    }
}

.default-color {
    color: #808080;
}
.red-color {
    color: #ffffff;
}
.green-color {
    color: white;
}
.black-color {
    color: white;
}
