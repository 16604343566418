.login-layout{
  #basic_basic_role{
    color: black !important;
  }
  &,.ant-layout{
    min-height: 100vh; 
    direction: ltr;
  }
  .login-form{
    height: 100vh;
    .header-titel{
      background-color: #303030;
      color: #fff;
      padding: 15px;
      border-radius: 6px 6px 0px 0px;
      p{
        margin: 0px;
      }
      span{
        font-weight: bold;
      }
    }
    .header-activity {
      padding: 15px;
      font-weight: 600;
      padding-bottom: 0px;
    }
    form#basic{
      // padding: 15px;
      padding: 30px 15px;
      .ant-col.ant-col-8.ant-form-item-label {
        display: flex;
      }
      
      .ant-col.ant-col-16.ant-col-offset-8.ant-form-item-control{
        display: contents;
      }
      button.ant-btn.ant-btn-primary.form-btn {
        background-color: #303030;
        // border-color: #303030;
        color: #fff;
        &:hover{
          box-shadow: 0px 0px 5px #303030;
        }
    }
    }
    .form-card{
      
      border-radius: 6px;
    }
    .form-btn{
      display: block;
      margin: auto;
    }
  }
}