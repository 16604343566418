.department-collapse {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-header-text {
    font-weight: 500;
  }
  .ant-collapse-item {
    border-radius: 10px;
    padding: 10px;
  }
}
