.cost-type-tab {

  // input {
  //     text-align: center !important;
  // }
  .ant-table,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table {
    border-radius: 0;
  }

  // #table-two-columns.cod-table-tow-columns {
  //   border-right: 1px solid #777;
  //   .ant-table-tbody > tr > td {
  //     border: 1px solid #777;
  //   }
  //   .ant-table-tbody > tr > td:first-child {
  //     border-right: none;
  //   }
  //   .ant-table-tbody > tr > td:last-child {
  //     // border-left: none;
  //     border-right: none;
  //   }
  //   .ant-table-tbody > tr:first-child > td {
  //     border-bottom: none;
  //   }
  //   .ant-table-tbody > tr:last-child > td {
  //     border-top: none;
  //   }
  // }
  .ant-table.ant-table-bordered>.ant-table-container {
    border: none;
    border-radius: 0;
  }

  .paragraph-tab-hover {
    div.ant-typography {
      margin-top: -1em;
      margin-bottom: 0;
      font-size: 10px;
      color: #fff;
      font-style: italic;
      inline-size: auto;
      writing-mode: horizontal-tb;
    }
    display: block;
    //display: none;
    // visibility: hidden;
    margin-left: -15px;
  }

  // .title-tab-hover:hover~.paragraph-tab-hover,.paragraph-tab-hover:hover {
  //   // visibility: visible;
  //   display: block;
  // }

  #table-two-columns,
  #table-for-panel {
    margin: 1rem 0;
    width: 100%;

    .ant-table-cell:nth-child(odd) {
      width: auto;
    }

    .ant-table-cell {
      text-align: left;
      //   border: 0;
      // background-color: #f2f2f2;
    }


  }

  .celery,
  .rhino,
  .eucalyptus,
  .denim,
  .thunderbird {
    // .ant-collapse-content-box {
    //     background-color: transparent;
    // }

    th.ant-table-cell {
      vertical-align: top;
      padding-left: 0 !important;
    }

    .ant-space {
      width: 100%;
      padding-left: 30%;
    }
  }

  .ant-collapse-content-box h5 {
    color: #1644b0;
    margin: 0;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .cost-type-editable {
    td.ant-table-cell {
      border: 0;
      text-align: right;
      padding: 6px;
    }

    td.ant-table-cell:has(input) {
      border: 0;
      text-align: right;
      padding-right: 0 !important;
    }

    .ant-table-cell:first-child,
    .ant-table-cell:nth-child(2) {
      text-align: center;
      padding-right: 0;
    }

    th.ant-table-cell {
      // background: #F2F6FF;
      font-size: 12px;
      // white-space: nowrap;
      text-align: right;
    }

    input {
      text-align: right !important;
      //padding-right: 20%;
    }
  }

  .thunderbird {

    #table-two-columns,
    #table-for-panel {
      .ant-table-cell {
        background-color: #c4221f2e;
        // border: 1px solid #777;
      }

      .ant-table-cell:nth-child(1) {
        background-color: #c4221f2e;
      }
    }

    .ant-collapse-content-box h5 {
      color: #c4221f;
    }
  }

  .celery {
    .ant-collapse-content-box h5 {
      color: #93c452;
    }
  }

  .eucalyptus {

    #table-two-columns,
    #table-for-panel {
      .ant-table-cell {
        background-color: #2fab582e;
        // border: 1px solid #777;
      }

      .ant-table-cell:nth-child(odd) {
        background-color: #2fab582e;
        width: 40%;
      }
    }

    .ant-collapse-content-box h5 {
      color: #2fab58;
    }
  }

  small {
    font-weight: normal;
    white-space: nowrap;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .ant-table-cell {
    padding-left: 2px;
    padding-right: 2px;
  }
}