.leverMng_table {
  padding: 0 0.5rem;
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin-top: -6px;
    margin-left: -6px;
    margin-bottom: 10px;
    padding: 0.2rem;

    .ant-table-title {
      // padding: 2px;
      // background: #eee;
      text-align: left;
    }
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    margin-bottom: 8px;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
  .ant-table-body {
    overflow-y: auto !important;
    max-height: 500px;
    //scrollbar-width: thin;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }
}
.lever_questions_form {
  .ql-snow .ql-stroke,
  .ql-snow .ql-stroke.ql-fill,
  .ql-snow .ql-fill {
    stroke: #c3c3c3;
    fill: #c3c3c3;
  }
  .ql-snow .ql-picker {
    color: #c3c3c3;
  }
}
