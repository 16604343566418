#rd-section {

    .title {
        text-align: center;
        font-weight: bold;
    }

    .colored {
        width: 95%;
        margin: 1rem auto;
        font-size: 0.65rem;

        .transparent {
            background-color: transparent !important;
            font-size: 1rem !important;
        }

        .red {
            color: #E12727;
        }
    }

    .Save_Btn {
        background-color: #00c851 !important;
        color: #fff
    }
}