.fl_table_div {
    margin-top: 30px;
    width: 100%;
    //height: 100vh;
    position: relative;

    .navigation-div {
        position: absolute;
        top: -45px;
        left: 10px;
    }

    .navigate-to-template-admin {}

    .navigate-to-discipline-admin {}

    .contact-an-administrator {
        color: burlywood;
    }

    .template-warning-div {
        color: burlywood;
        margin-right: 10px;
    }

    .template-warning-link-div {
        display: flex;
        text-align: center;
        justify-content: flex-start;
    }

    .navigate-to-template-link {
        font-style: oblique;
        color: cornflowerblue;
        border-bottom: 1px solid cornflowerblue;
    }

    .milestone-name-title_div {
        display: flex;
        position: relative;
        height: 100%;
        text-align: center;
        justify-content: space-around;
    }

    .milestone-delete-div {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .milestone-name-div {
        height: fit-content;
        align-self: center;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 2px;
    }

    .fl_table_spinner_div {
        margin: auto;
    }

    .phaseNameDiv {
        padding-right: 25px;
    }

    .phase_duration_value_div {
        height: 100%;
        // background-color: white;
        display: -ms-inline-grid;
        display: inline-grid;
        width: 100%;
    }

    .phase_duration_literal {

        display: block;
        padding: 5px;
        align-self: flex-start;
    }

    .phase_duration_value {
        background-color: #dcf0ff;
        display: block;
        align-self: flex-end;
    }

    .ant-table-cell {
        font-size: 12px;
        padding: 0px;
        height: 30px;
    }

    .ant-table-tbody {
        tr.ant-table-row.ant-table-row-level-0 td {
            padding: 0px 0px;
        }
    }

    .phaseCellDiv {
        position: relative;
    }

    .phaseControlDiv {
        position: absolute;
        display: flex;
        text-align: center;
        justify-content: space-between;
        top: -26px;
        right: 2px;
    }

    .editPhaseControlDiv {
        position: absolute;
        display: flex;
        text-align: center;
        justify-content: space-between;
        top: -26px;
        right: 2px;
    }

    .phaseHeaderDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .phaseHeaderContentDiv {
        padding-left: 5px;
    }

    .PhaseHeaderActualDateDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .PhaseHeaderActualDateValue {
        padding-left: 5px;
    }

    .PhaseHeaderPlannedDateDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .PhaseHeaderDurationDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .PhaseHeaderPlannedDateValue {
        padding-left: 5px;
    }

    .PhaseHeaderDuartionValue {
        padding-left: 5px;
    }

    .MileStoneHeadrDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .MileStoneHeaderValueDiv {
        padding-left: 5px;
    }

    .ActualdateHeaderDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .PlannedDateHeaderDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .ActualdateHeaderValueDiv {
        padding-left: 5px;
    }

    .PlannedDateHeaderValueDiv {
        padding-left: 5px;
    }

    .GateHeaderDiv {
        // background-color: #d9d9d9;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .GateHeaderValueDiv {
        padding-left: 5px;
    }

    .walaa_phase {
        padding-left: 35px;
        font-weight: 700;
    }

    .fte-total-header {
        font-weight: 700;
        padding: 5px
    }

    .DisciplinesSpansDiv {
        padding-left: 5px;
        position: relative;
    }

    .DisciplineOrderSpan {
        font-weight: 800;
    }

    .DisciplineNameSpan {
        font-weight: 400;
        margin-left: 5px;
    }

    .phaseFormItemDiv {
        padding: 2px;
    }

    .editPhaseBtnsDiv {
        display: flex;
        text-align: center;
        justify-content: space-between;
    }

    .required_message {
        font-size: 12px !important;
        text-align: left;
    }

    .validation_error_message {
        font-size: 10px !important;
        text-align: left;
    }
}

.toolTip-container-div {
    display: flex;
    text-align: center;
    justify-content: center;
}

.tooltip_text_div {
    padding: 10px;
    text-align: left;
    margin-right: 25px;
}

.tooltip_info_icon_div {
    position: absolute;
    top: 10px;
    right: 5px;
    color: green;
}

.tooltip_icon_div {
    position: absolute;
    top: 10px;
    right: 5px;
}

.ant-tooltip-inner {
    background-color: rgba($color: #000000, $alpha: 0.95);
}