.change_project_container {
    text-align: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.columns_options_div {
    margin-bottom: 20px;
    margin-top: 30px;
    display: flex;
    text-align: center;
    justify-content: flex-end;
}

.columns_options_div {
    margin-right: 20px;
}

.toast_success_title {
    color: #008000;
    font-size: 14px;
    font-weight: 400;
}

.toast_error_title {
    color: #a92027;
    font-size: 14px;
    font-weight: 400;
}

.toast_success_descrition {
    font-size: 12px;
    font-weight: 300;
}

.toast_success_title_project_name {
    font-weight: 600;
    font-style: italic;
}