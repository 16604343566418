.TopLevelchartContainer {
    height: 35vh;
    padding-bottom: 12vh;
    text-align: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

// .columns_options_div {
//     margin-bottom: 20px;
//     margin-top: 30px;
//     display: flex;
//     text-align: center;
//     justify-content: end;
// }

// .columns_options_div{
//     margin-right: 20px;
// }
#EbitTabs {
    .ant-tabs-content-holder {
        // border: 1px solid #f0f0f0;
        // padding-bottom: 0 !important;
        border-top: 0;
        padding-top: 5px !important;
    }


    .ant-tabs-content {
        height: 100% !important;
    }

    .ant-tabs {
        height: 100% !important;
    }

    .scroll-100vh-300 {
        max-height: 100% !important; //calc(100vh - 250px);
        overflow: auto;
    }
}