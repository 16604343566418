.fl_selection_main_div{
    padding: 10px;
    margin-top: 20px;
    max-height: 55vh;
    overflow-x: scroll;

}
.fl_selection_radio_div{
    width: 180px;
    text-overflow: ellipsis; 
    overflow: hidden;
    white-space: nowrap;
    
}

.fl_project_edit_form{
    width: 100%;
    padding: 10px;
    box-shadow: 3px 3px 3px 3px #888888;
    margin-bottom: 10px;
    margin-top: 10px;
    .ant-form-item{
        margin: 0px 0 10px !important;
    }
    // .actual-start-date-picker{
    //     width: 47%;
    // }
    // .planned-start-date-picker{
    //     width: 51%;
    // }
}

.radio_item_div{
    display: flex;
    justify-content: space-between;
    .radio-Btns-edit-delete{
        display: flex;
        
    }
}