#colored-table {

    // .ant-table-cell-fix-left,
    // .ant-table-cell-fix-right {
    //     // background-color: #FFF;
    // }

    td {
        width: 120px !important;
    }

    // .ant-table-cell-row-hover {
    //     background: hsla(40, 96%, 53%);
    // }

    table tr:hover {
        .ant-table-cell {
            background: hsla(40, 96%, 53%);
        }
    }

    table tr:focus-within {
        .ant-table-cell {
            background: hsla(40, 96%, 53%);
        }
    }

    table tr:focus-visible {
        .ant-table-cell {
            background: hsla(40, 96%, 53%);
        }
    }

    tr {
        font-size: 1.4vh !important;
    }


    tr:last-child {

        td {
            font-size: 1.6vh !important;
            font-weight: bold;
            filter: brightness(100%);
            border-left: none !important;
            border-right: none !important;

        }

        td:nth-child(n+7) {
            border-top: solid 0.5px black !important;
        }
    }

    tr:first-child {
        font-size: 1.6vh !important;

        th:nth-child(1),
        th:nth-child(2) {
            font-size: 1.4vh !important;


        }

        th:nth-child(n+4) {
            background-color: #eeeeee !important;
            border-top: solid 0.5px black !important;
        }

        th:nth-child(1)::before {
            width: 0px !important;
        }

        th:nth-child(1)::after {
            width: 0px !important;
        }

        th:nth-child(2)::before {
            width: 0px !important;
        }

        th:nth-child(2)::after {
            width: 0px !important;
        }

        th:nth-child(even):not(:nth-child(2)) {
            filter: brightness(80%);
        }

        th:nth-child(2n+4) {
            border-left: solid 0.5px black !important;
            border-right: solid 0.5px black !important;
        }

    }

    tr:nth-child(2) {

        th:nth-child(8n+7),
        th:nth-child(8n+8) {
            filter: brightness(80%);
        }

        th:nth-child(8n+6) {
            border-left: solid 0.5px black !important;
            // border-bottom-left-radius: 10px;
            filter: brightness(80%);
        }

        th:nth-child(8n+9) {
            border-right: solid 0.5px black !important;
            // border-bottom-right-radius: 10px;
            filter: brightness(80%);
        }

        th {
            background-color: #eeeeee !important;
            color: rgba(0, 0, 0, .75);
        }
    }

    th {
        // background-color: #FFF !important;
        padding-bottom: 2px;
        padding-top: 0;
        padding-left: 0%;
        padding-right: 0%;
        font-weight: normal;
        text-align: center;
        font-size: 1.4vh !important;
        ;
    }

    th[colspan="4"] {
        font-weight: bold;
        // padding: 0.15rem;
        font-size: 1.4vh !important;
        ;

    }

    td {
        padding: 0px;
        font-weight: normal;
        text-align: center;
        // padding: 0.1rem;
        font-size: 1.4vh !important;
        ;

    }





    td:nth-child(8n+8),
    td:nth-child(8n+9) {
        filter: brightness(75%);
    }

    td:nth-child(8n+7) {
        border-left: solid 0.5px black !important;
        // border-bottom-left-radius: 10px;
        filter: brightness(75%);
    }

    td:nth-child(8n+10) {
        border-right: solid 0.5px black !important;
        // border-bottom-right-radius: 10px;
        filter: brightness(75%);
    }

    // td:nth-child(11),
    // td:nth-child(12),
    // td:nth-child(13),
    // td:nth-child(14) {
    //     // background: #ddd !important;
    // }

    td:first-child {
        text-align: left;
        //font-weight: 500;
    }

    h3 {
        font-weight: bold;
        text-align: center;
    }

    .ant-form-item-control-input-content {
        label {
            font-weight: bold;
            font-size: small;
        }

        .ant-input-affix-wrapper,
        .ant-input-number-input-wrap {
            padding: 0;
            //padding: 4px 4px;

            .anticon.anticon-close-circle {
                color: #010101;
            }
        }

        .ant-input,
        .ant-input-number-input {
            width: 100%;
            text-align: center;
            // font-size: 1vh;
            margin: 0;
            padding: 0;
            // line-height: 1vh !important;
            // height: 1vh !important;
            //padding: 0rem;
        }
    }

    .ant-input-number-input-wrap {
        height: 2.7vh;
        padding-top: 0px;
    }

    .ant-table-wrapper {
        .ant-input-number-input {
            width: 100%;
            text-align: center;
            vertical-align: top;
            // padding-top: 0;
            // padding-bottom: 0;
            font-size: 1.4vh !important;
            // line-height: 8px !important;
            margin: 0px;
            // margin-bottom: 15px;
            height: 100% !important;
            // min-height: 0.01vh;
            // max-height: 0.01vh;
            //padding: 0rem;
        }
    }

    .ant-form-item-explain-error {
        font-size: xx-small;
    }

    // .ant-btn.ant-btn-primary {
    //     background-color: #1644B0;
    //     border-color: #1644B0;
    // }
    .ant-form-item {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    #input-form {
        margin: 1.5rem;
        overflow: auto;
    }


    .sub-header {
        display: block;
        text-align: center;
        font-weight: normal;
    }

    .EditModal {
        width: fit-content;

    }


}