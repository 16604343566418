.indDis_best_practice{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
    // .ant-form-item {
    //     width: 100px;
    //   }
      .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th , .ant-table-cell{
      text-align: center;
    }  
}