/* .dproject_table_wrapper .ant-table-tbody>tr>td {
    background-color: white !important;
    color: black !important;
} */
.Dproject_wrapper {
  .coloptions_div{
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .col_options_wrapper{
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .columns-popover{
    align-self: center;
  }
  .DProject_control_div {
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin: 20px 0px 10px 0px;
    padding: 0px;
    .dprojects_left,
    .dprojects_right {
      display: flex;
      text-align: center;
    }
  }

  .dproject_table_wrapper {
    .ant-table-cell {
      font-size: 12px;
      padding: 0px;
    }
    .ant-table-tbody {
      tr.ant-table-row.ant-table-row-level-0 td {
        padding: 5px 0px;
      }
    }
    .General_project_data_class {
      width: 100%;
    }
    .q_1121,
    .q_1122,
    .q_1123 {
      white-space: pre-wrap;
    }
    .q_2211,
    .q_2212,
    .q_2221,
    .q_2311,
    .q_2312,
    .q_2321,
    .q_2322,
    .q_2323,
    .q_2324,
    .q_2411,
    .q_2421,
    .q_2422,
    .q_2431,
    .q_2432,
    .q_2511 {
      white-space: pre-wrap;
    }
    .q_3411,
    .q_3421,
    .q_3431,
    .q_3432 {
      white-space: pre-wrap;
    }
    .q_4522 {
      white-space: pre-wrap;
    }
    .undefinedKPI {
      white-space: pre-wrap;
    }
  }

  .add_d-project_div {
    margin-right: 10px;
  }

  .save_d-project_div {
    margin-right: 10px;
  }

  .filter_by_lever_div {
    margin-right: 10px;
  }

  .add_comment_textarea {
    margin: 20px 0px !important;
  }

  .save_comment_Btn {
    margin-left: 10px;
  }

  .add_comment_button_div {
    /* margin-left: 10px; */
  }

  .add_comment_div {
    margin-bottom: 20px;
  }

  .custom_cell_Edit_Btn_div {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .custom_cell_Btns {
    display: flex;
    text-align: center;
    justify-content: space-around;
    padding-top: 2px;
  }

  .confirm_Edit_Btn {
    border: none !important;
  }

  .cancel_Edit_Btn {
    border: none !important;
  }

  .edit_Btn_Icon {
    border: none !important;
  }

  .dprojects_comments_td {
    padding: 3px !important;
  }

  .dprojects_comments_notaddingproject_div {
    cursor: pointer !important;
    z-index: 10000000 !important;
  }

  .dprojects_comments_ul {
    padding: 0px !important;
    text-align: left !important;
    max-height: 50px !important;
    overflow-y: scroll !important;
  }

  .dprojects_comments_ul_li {
    max-width: 150px !important;
    overflow-x: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .d_projects_operations_div {
    display: flex !important;
    text-align: center !important;
    justify-content: space-around !important;
  }

  .d_projects_edit_Btn_div {
    display: flex;
    text-align: center;
  }

  .d_projects_delete_Btn_div {
    display: flex;
    text-align: center;
  }

  .d_projects_includeinlevers_check_div {
    display: flex;
    text-align: center;
  }

  .edit_input_num {
    padding: 0px !important;
    width: 100% !important;
    height: 30px !important;
    text-align: center !important;
    font-size: 12px !important;
  }

  .edit_input_str {
    padding: 0px !important;
    height: 30px !important;
    text-align: center !important;
    font-size: 12px !important;
  }

  .validation-message {
    text-align: left;
    position: absolute;
    top: 0px;
    margin: 0px;
    padding: 0px;
    left: 25px;
  }

  .required-title {
    font-weight: 800;
  }
  .required_list {
    margin-left: 20px;
  }
}

.list-comments {
  max-height: 400px;
  overflow: scroll;
}
.cancel_save_Btn {
  margin-right: 5px;
}
.comments-list-header{
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
  justify-content: space-between;
}
.comments-litteral-title{
  font-weight: bolder;
}
.order-btns-div{
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.up-btn{
  margin-right: 3px;
}
