.chartdetails-chart-container{
    text-align: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
}
.radarTitle{
    display: block;
    font-weight: 700;
}
.chartType{
    font-weight: 600;
}
.radar_title{
    font-size: 12px;
    margin-bottom: 15px;
}

.controls-details-chart-container{    
    position: relative;
    width: 100%;
    height: 100%;
}
