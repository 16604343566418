.frontLoadingDepartment_component {
    .ant-form-item-control-input-content {
      display: grid;
      place-items: center;
    }
    
    
  }
  .color-picker-required label::before {
    display: inline-block;
    margin-right: 4px;
    color: #a92027;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  #viewOrder.ant-input-number-input{
    padding: .2rem .5rem;
  }