/* permissions components */
// .span-header-permission {
//     -ms-writing-mode: tb-rl;
//     -webkit-writing-mode: vertical-rl;
//     writing-mode: vertical-rl;
//     transform: rotate(180deg);
//     white-space: break-spaces;
//     height: 20%;
// }

.permissions-component {
   
    th.ant-table-cell:not(:first-child) {
        height: 100px;
        padding: 0;
    }

    .rotated-text {
        font-size: 0.6rem;
        word-break: keep-all;
        text-align: left;
        transform: rotate(270deg);
    }

    .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-between;
    }

    .ant-tabs-tab {
        display: block;
        width: 100%;
        text-align: center;
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
    }

    .ant-tabs-tab {
        font-weight: bold;
    }

    .ant-table-cell-scrollbar {
        overflow: auto;
    }

    .ant-avatar {
        display: inline-block;
    }

    .ant-typography {
        display: inline-block;
        font-size: 0.8rem;
        font-weight: normal;
    }
}