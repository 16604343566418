.columns-filter-ia-popover {
  &.ant-popover {
    .ant-popover-inner-content {
      padding: 0;
      // max-width: 50vw;
      width: 500px;
      height: 500px;
      overflow-y: auto;

      p {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0;
        line-height: normal;
        padding-top: 10px;
      }

      ul .hidden-items,
      ul>div {
        display: flex;
        flex-wrap: wrap;
      }

      .hidden-items {
        min-height: 65px;
      }

      li {
        width: 100%;
      }
    }
  }

  .ant-popover-content .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
}


.interface-analysis {
  // .top-options{
    
  // }
  .voting-process-body{
    .ant-space-item {
      width: 100%;
    }
  }  
  .voting-process-header{
    margin-bottom: 10px;
    .ant-space-item {
      width: 100%;
    }
  }
  .ia-process {
    .ant-descriptions-item-content {
      justify-content: center;
    }

    .ant-descriptions-item-label {
      font-weight: 700;
    }
  }

  .ia_table {

    .ant-table.ant-table-small .ant-table-footer,
    .ant-table.ant-table-small .ant-table-tbody>tr>td,
    .ant-table.ant-table-small .ant-table-thead>tr>th,
    .ant-table.ant-table-small .ant-table-title,
    .ant-table.ant-table-small tfoot>tr>td,
    .ant-table.ant-table-small tfoot>tr>th {
      padding: 1.5px;
    }

    .btn-vote-avg {
      padding: 0px;
      border: none;
      margin: 0px;
      height: auto;
    }
  }

  .ia_tool_anonymous {
    padding: .5rem;
  
    .ant-layout-header {
      padding: 1rem 0.5rem;
      height: 90px;

      .ant-typography {
        color: #e3e3e3;
        margin-bottom: 0;
      }
     
    }

    .ant-layout-content {

      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      .ant-typography {
        margin-bottom: 0;
      }

      .ant-table-container {
        border: none;
        border-bottom: 5px solid;
        border-top: 5px solid;
        border-image: -moz-linear-gradient(left,
            #fed330 0%,
            #fed330 20%,
            #e63841 20%,
            #e63841 20%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #5ed6fd 60%,
            #45c33b 60%,
            #45c33b 80%,
            #1172c0 80%,
            #1172c0 80%,
            #1172c0 100%);
        /* FF3.6+ */
        border-image: -webkit-gradient(linear,
            left top,
            right top,
            color-stop(0%, #fed330),
            color-stop(20%, #fed330),
            color-stop(20%, #e63841),
            color-stop(20%, #e63841),
            color-stop(40%, #e63841),
            color-stop(40%, #5ed6fd),
            color-stop(40%, #5ed6fd),
            color-stop(40%, #e63841),
            color-stop(40%, #5ed6fd),
            color-stop(40%, #5ed6fd),
            color-stop(60%, #5ed6fd),
            color-stop(60%, #45c33b),
            color-stop(80%, #45c33b),
            color-stop(80%, #1172c0),
            color-stop(80%, #1172c0),
            color-stop(100%, #1172c0)) 1 stretch repeat;
        /* Chrome,Safari4+ */
        border-image: -webkit-linear-gradient(left,
            #fed330 0%,
            #fed330 20%,
            #e63841 20%,
            #e63841 20%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #5ed6fd 60%,
            #45c33b 60%,
            #45c33b 80%,
            #1172c0 80%,
            #1172c0 80%,
            #1172c0 100%) 1 stretch repeat;
        /* Chrome10+,Safari5.1+ */
        border-image: -o-linear-gradient(left,
            #fed330 0%,
            #fed330 20%,
            #e63841 20%,
            #e63841 20%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #5ed6fd 60%,
            #45c33b 60%,
            #45c33b 80%,
            #1172c0 80%,
            #1172c0 80%,
            #1172c0 100%) 1 stretch repeat;
        /* Opera 11.10+ */
        border-image: -ms-linear-gradient(left,
            #fed330 0%,
            #fed330 20%,
            #e63841 20%,
            #e63841 20%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #5ed6fd 60%,
            #45c33b 60%,
            #45c33b 80%,
            #1172c0 80%,
            #1172c0 80%,
            #1172c0 100%) 1 stretch repeat;
        /* IE10+ */
        border-image: linear-gradient(to right,
            #fed330 0%,
            #fed330 20%,
            #e63841 20%,
            #e63841 20%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #e63841 40%,
            #5ed6fd 40%,
            #5ed6fd 40%,
            #5ed6fd 60%,
            #45c33b 60%,
            #45c33b 80%,
            #1172c0 80%,
            #1172c0 80%,
            #1172c0 100%) 1 stretch repeat;
        /* W3C */
      }

     
    }

    .ant-table-thead {
      height: 10vh;

      .prepare_col_name {
        text-align: center;
        font-weight: 700;
        font-size: 1.2rem;
        border-width: 3px;
        //  background-color: #dddddd;
        // background-color: #010101;
      }
    }

    .ant-table-tbody {
      .prepare_col_name {
        text-align: center;
      }

      td.prepare_col_name:first-child {
        font-weight: 700;
      }

      td.prepare_col_name textarea {
        border: none;
        outline: none;
      }
    }

    .ant-rate {
      font-size: 1rem;
    }

    .ia-btn {
      margin-left: auto;
      margin-right: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 20px 50px;
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 1.3rem;
      font-weight: 700;
    }

    .ant-layout-header {
      display: flex;
      // justify-content: center;
      align-items: center;
    }
  }
}