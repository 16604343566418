.popconfirm_async {
  .version-icon-off,
  .version-icon-on {
    padding: 1.5px;
    border-radius: 50%;
    color: #e0e0e1;
    cursor: pointer;
  }
  .version-icon-off {
    background-color: red;
  }
  .version-icon-on {
    background-color: green;
  }
}
