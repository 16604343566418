.selfAssessment-tap {
  .ant-tabs-nav-list {
    width: 90%;
    justify-content: space-evenly;
    margin-right: auto;
    margin-left: auto;
    .ant-tabs-tab-btn {
      font-weight: 600;
    }
  }
  .ant-collapse-header-text {
    font-weight: 500;
  }
  .ant-collapse-item{
    border-radius: 10px;
    padding: 10px;
  }
}
