.columns-filter-easyaccess-popover {
  &.ant-popover {
    .ant-popover-inner-content {
      padding: 0;
      width: 30vw;
      //width: 200px;
      overflow: scroll;
      height: 30vh;
      //   p {
      //     display: block;
      //     width: 100%;
      //     text-align: center;
      //     margin: 0;
      //     line-height: normal;
      //     padding-top: 10px;
      //   }

      //   ul .hidden-items,
      //   ul>div {
      //     display: flex;
      //     flex-wrap: wrap;
      //   }

      //   .hidden-items {
      //     min-height: 65px;
      //   }

      li {
        width: 100%;
      }
    }
  }
}

.ea_lever_table {
  th {
    padding: 1px !important;

    .ant-table-cell {
      font-size: 9px;
    }
  }
  td.ant-table-cell-fix-left{
    z-index: 90 !important;
  }
  td {
    padding: 1px !important;

 
    
    .ant-table-cell {
      font-size: 9px;
    }
  }
}