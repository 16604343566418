.general-model-component {
  .ant-btn-primary {
    margin-bottom: 10px;
  }
}
.ant-modal-content {
  padding-bottom: 24px;
  .ant-modal-body {
    padding-bottom: 0;
  }
  .ant-modal-footer {
    border-top: 0;
  }
  // .ant-btn-primary {
  //   background-color: #7a7a7a;
  //   border-color: #7a7a7a;
  // }
}
