.ProjectName {
    display: none;
}

.show_col {
    display: table-cell;
}

.hide_col {
    display: none;
}
.filterDropdown_div{
    padding: 8px;
}