.chart-title {
    margin: auto;
    max-width: 300px;
    //text-overflow: ellipsis;
    overflow: hidden;
    //white-space: nowrap;
    white-space: normal;
    font-weight: 700;
    font-size: 12px;
}

.chart-description {
    margin: auto;
    max-width: 300px;
    //text-overflow: ellipsis;
    overflow: hidden;
    //white-space: nowrap;
    white-space: normal;
    font-weight: 600;
    font-size: 12px;
}

.chart-unit {
    margin: auto;
    max-width: 300px;
    //text-overflow: ellipsis;
    overflow: hidden;
    //white-space: nowrap;
    white-space: normal;
    font-weight: 500;
    font-size: 12px;
}