.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.estimate-panel,
.evaluation-panel {
    // text-transform: capitalize;

    input {
        text-align: right;
    }

    .ant-collapse-content-box {
        margin: 0px 10px 5px 10px;
        // border: 1px solid #ccc;
    }

    // .ant-input-group-addon:hover,
    // :focus {
    //     padding-right: 3ch;
    // }

    .chartTitle {
        font-size: 2vh; //1.5rem;
    }

    .chartType {
        font-size: 1.5vh;
    }

    .barChart_container .chart_container {
        margin: 15px 10px 15px 10px;
        padding: 0px;
        // border: 1px solid #ccc;

        [data-chart-source-type="G2Plot"] {
            padding: 20px;
        }
    }

    .ant-collapse-header {
        border-radius: 15px;
        background-color: #808080;
    }

    .ant-slider {
        margin-top: 5px;
    }

    .eucalyptus {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #2FAB58;
            color: #FFFFFF;
            // font-weight: bold;
        }
    }

    .ant-collapse-content-box {
        margin: 0px 10px 5px 10px;
        // border: 1px solid #ccc;
    }

    .thunderbird {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #c4221f;
            color: #FFFFFF;
            // font-weight: bold;
        }
    }

    .rhino {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #293560;
            color: #FFFFFF;
            // font-weight: bold;
        }
    }

    .ant-collapse-item {
        margin-top: 15px;
    }

    .ant-collapse>.ant-collapse-item:last-child,
    .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
        border-radius: 15px;

    }
}

.estimate-tab,
.cost-type-tab {
    margin-top: 15px;

    .ant-collapse-ghost {
        margin-right: 10px;
        margin-left: 10px;
    }

    .estimate-custom-panel,
    .cost-type-custom-panel {
        border-radius: 20px;
        border: 0;
        margin-bottom: 10px;

        .ant-collapse-item {
            background-color: #101010;
        }

        .ant-collapse-header-text {
            width: 100%;
        }

        .ant-collapse-header input {
            background: transparent;
            border: 0;
        }

        .ant-collapse-content {
            border-top: 0px;
        }

        .ant-collapse-expand-icon,
        h5,
        .ant-collapse-header-text {
            color: #FFFFFF;
        }

        .ant-collapse-content-box {
            margin: 0px 10px 5px 10px;
            // border: 1px solid #ccc;
        }

        .ant-collapse-header {
            border-radius: 15px;
            background-color: #808080;
        }
    }

    .ant-collapse {
        border: 0px solid #d9d9d9;
    }

    .celery {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #93c452;
        }

    }

    .eucalyptus {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #2FAB58;
        }
    }

    .denim {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #1576BD;
        }
    }

    .thunderbird {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #c4221f;
        }
    }

    .rhino {
        background-color: transparent;

        .ant-collapse-header {
            background-color: #293560;
        }
    }

    .ant-collapse>.ant-collapse-item:last-child,
    .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
        border-radius: 15px;
    }
}

.evaluation-panel {
    // margin-top: 15px;

    // .ant-collapse-ghost {
    //     margin-right: 10px;
    //     margin-left: 10px;
    // }

    // .estimate-custom-panel,
    // .cost-type-custom-panel {
    //     border-radius: 20px;
    //     border: 0;
    //     margin-bottom: 10px;

    //     .ant-collapse-item {
    //         background-color: #101010;
    //     }

    //     .ant-collapse-header-text {
    //         width: 100%;
    //     }

    //     .ant-collapse-header input {
    //         background: transparent;
    //         border: 0;
    //     }

    //     .ant-collapse-content {
    //         border-top: 0px;
    //     }

    //     .ant-collapse-expand-icon,
    //     h5,
    //     .ant-collapse-header-text {
    //         color: #FFFFFF;
    //     }

    //     .ant-collapse-content-box {
    //         margin: 0px 10px 5px 10px;
    //         border: 1px solid #ccc;
    //     }

    //     .ant-collapse-header {
    //         border-radius: 15px;
    //         background-color: #808080;
    //     }

    //     .ant-collapse-content-box {
    //         background-color: #80808041;
    //     }
    // }

    .ant-collapse {
        border: 0px solid #d9d9d9;

        .ant-collapse-item {
            border: 0px solid #d9d9d9;
        }
    }

    .ant-collapse-content {
        border-top: 0px;
    }

    // .rhino {
    //     background-color: transparent;

    //     .ant-collapse-header {
    //         background-color: #293560;
    //     }

    //     .ant-collapse-content-box {
    //         background-color: #29356041;
    //     }
    // }

    .ant-collapse>.ant-collapse-item:last-child,
    .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
        border-radius: 15px;
    }
}