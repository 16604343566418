.ind-Bp {
  // overflow: auto;
  // height: 80vh;
  .ind_bp-form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 2% 3%;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
  }
  .ant-form-vertical .ant-form-item-label,
  h5.ant-typography {
    text-align: center;
  }
  h5.ant-typography {
    margin-bottom: 0.1em;
    margin-left: 0.2em;
  }
  .ant-input-number {
    width: 95%;
  }
  .ant-alert.ant-alert-error {
    padding: 5px;
    margin-bottom: 0.3rem;
    .ant-alert-message {
      font-size: 10px;
      font-weight: 600;
    }
  }
}
