.profile-admin {
  .ant-space-item .ant-typography {
    text-align: center;
  }
  .ant-space-item .col-image {
    .ant-btn-default:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-space-item .ant-tag {
    padding: 5px 10px;
    font-size: 1.1rem;
  }
}
