#table-two-columns,
#table-for-panel {
    margin: auto;

    tr {

        td:nth-child(odd),
        th:nth-child(odd) {
            background-color: #F7DA4621;
            font-weight: bold;
        }

        // td:nth-child(2n),
        // th:nth-child(2n) {
        //     background-color: #1644B014;
        // }
    }

    th {

        display: none;
    }

    td {
        // font-weight: bold;
        font-size: small;
        // color: #676767;
        // width: 25%;
        padding: 5px;
        text-align: center;
        /* vertical-align: top; */
        font-size: 0.7rem;
        overflow-wrap: break-word;
    }

    small {
        font-weight: normal;
        white-space: nowrap;
    }

    p {
        padding: 0;
        margin: 0;
    }
}