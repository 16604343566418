.CostTypesVsProjectTab_main_tab {
    .CostTypesVsProjectTab_container {
        display: flex;
        text-align: center;
        justify-content: space-between;
        
    }
    .disciplines-departments-tables-div
    {
        width: 50%;
        height: '71vh';
    }
    .charts-div {
        width: 50%;
    }
    .discipline-dropdown-div {
        margin-bottom: 10px;
    }
    .RTDCurve-chart {
        width: 100%;
        height: 35vh;
    }
    .discipline-rndcosts-chart-div {
        width: 100%;
        height: 37vh;
        .chart_container {
            width: 100%;
            height: 36vh !important;
        }
    }
}
