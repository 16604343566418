/* .current_project_card_title_text{
    font-weight: 700;
    font-size: 20px;
} */
.current_project_card {
  width: 100%;
}
.current_project_card .ant-row {
  flex-wrap: nowrap;
  align-items: center;
}
.ant-card-body {
  padding: 0px !important;
}

.Current_project_footer_Btns {
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 750px;
}

/* .current_project_card_title{
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin:10px 0px 10px 0px;
    padding: 0px 10px 0px 20px;
} */

.Form-Cancel_Btn {
  margin-left: 5px;
}

.form_btns_div {
  margin-top: 5px;
  display: flex;
  text-align: center;
  justify-content: flex-end;
}

.side-current-proejct {
  padding: 15px;
  margin: 15px;
  display: block;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 50px;
}

.header-current-proejct {
  padding: 15px;
  margin: 15px;
  display: block;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 20px;
}

.current-project-value {
  padding: 15px;
  margin: 15px;
  display: block;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 50px;
  background-color: #bcc7db;
}

@media only screen and (max-width: 1450px) {
  .current_project_card_wrapper.current_project-responsive-part1
    .current-project-value {
    padding: 10px;
    margin: 10px;
  }
  .current_project_card_wrapper.current_project-responsive-part1
    .header-current-proejct {
    padding: 10px;
    margin: 10px;
  }
  .current_project_card_wrapper.current_project-responsive-part1
    .side-current-proejct {
    padding: 10px;
    margin: 10px;
  }
  .current_project_card_wrapper.current_project-responsive-part2
    .Current_project_footer_Btns {
    width: auto;
    column-gap: 0.5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .current_project_card_wrapper.current_project-responsive-part2
    .ant-card-head-wrapper {
    flex-direction: column;
    justify-content: center;
  }
  .current_project_card_wrapper.current_project-responsive-part1
  .current-project-submit-btn
    .ant-form-item-control {
    margin-left: initial;
    text-align: center;
  }
  .current_project_card_wrapper.current_project-responsive-part2
    .ant-card-extra {
    margin-left: initial;
  }
}
@media only screen and (max-width: 991px) {
  .current_project_card_wrapper.current_project-responsive-part1
    .current-project-value {
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    height: 40px;
  }
  .current_project_card_wrapper.current_project-responsive-part1
    .header-current-proejct {
    padding: 5px;
    margin: 5px;
    font-size: 12px;
  }
  .current_project_card_wrapper.current_project-responsive-part1
    .side-current-proejct {
    padding: 5px;
    margin: 5px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 791px) {
  .current_project_card_wrapper.current_project-responsive-part1
    .current-project-value {
    padding: 3px;
    margin: 3px;
    font-size: 10px;
    height: 35px;
  }
  .current_project_card_wrapper.current_project-responsive-part1
    .header-current-proejct {
    padding: 3px;
    margin: 3px;
    font-size: 10px;
  }
  .current_project_card_wrapper.current_project-responsive-part1
    .side-current-proejct {
    padding: 3px;
    margin: 3px;
    font-size: 10px;
  }
}
