.login-layout {

    &,
    .ant-layout {
        min-height: 100vh;
        direction: ltr;
    }

    .verification-list {
        height: 100vh;

        .form-card {
            padding: 50px;
            border-radius: 6px;
            form.ant-form.ant-form-horizontal.form-card{
                padding: 0;
            }
        }

        .form-btn {
            display: block;
            // margin: 50px auto;
            margin: auto;
            background-color: #303030;
            border-color: #303030;
            color: #fff;
            &:hover{
              box-shadow: 0px 0px 5px #303030;
            }
        }
    }    
}