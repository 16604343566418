.table-tasks{
    tr.ant-table-expanded-row.ant-table-expanded-row-level-1 td:empty {
        display: none;
    }
}
.survey-main-div {
    .ant-form-item {
        margin-bottom: 0px !important;
    }


    .ant-table-cell {
        font-size: 12px;
        padding: 0px;
        height: 30px;
    }

    .ant-table-tbody {
        tr.ant-table-row.ant-table-row-level-0 td {
            padding: 0px 0px;
        }
    }

    .submit-div {
        padding-left: 10px;
        padding-bottom: 10px;
    }

    .hoursBerWeek_form_input {
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: #666666;
        border: none;
        color: white;
    }

    .hoursBerWeek_form_input_ERR {
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: red;
        border: none;
        color: white;
    }

    .daysPerMonth_form_input {
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: #666666;
        border: none;
        color: white;
    }

    .daysPerMonth_form_input_ERR {
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: red;
        border: none;
        color: white;
    }

    .daysPerYear_form_input {
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: #666666;
        border: none;
        color: white;
    }

    .daysPerYear_form_input_ERR {
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: red;
        border: none;
        color: white;
    }

    .groupAverage_div {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .groupAverage_ERR_div {
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: red;
        border: none;
        color: white;
        border-radius: 5px;
    }

    .groupAverage_value_div {
        display: flex;
        justify-content: center;
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
    }

    .groupAverage_ERR_span {
        align-self: center;
    }

    .groupAverage_value_span {
        align-self: center;
    }

    .taskSharePercent_div {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .taskSharePercent_ERR_div {
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: red;
        border: none;
        color: white;
        border-radius: 5px;
    }

    .taskSharePercent_value_div {
        display: flex;
        justify-content: center;
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
    }

    .taskSharePercent_ERR_span {
        align-self: center;
    }

    .taskSharePercent_value_span {
        align-self: center;
    }

    .totalAverage_div {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .totalAverage_ERR_div {
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        background-color: red;
        border: none;
        color: white;
        border-radius: 5px;
    }

    .totalAverage_value_div {
        display: flex;
        justify-content: center;
        background-color: green;
        padding: 0px !important;
        height: 30px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 12px !important;
        border-radius: 5px;
    }

    .totalAverage_ERR_span {
        align-self: center;
    }

    .totalAverage_value_span {
        align-self: center;
    }

}