.BmProject-component {
  .ant-picker {
    width: 100%;
  }
  .table_bmProject {
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
      margin-top: -6px;
      margin-left: 40px;
      margin-bottom: -14px;
      margin-right: -6px;
    }
    .ant-table.ant-table-bordered > .ant-table-container {
      margin-bottom: 8px;
    }

    // .ant-table-expanded-row.ant-table-expanded-row-level-1 th.ant-table-cell {
    //   background-color: #f3f3f3;
    // }
    .ant-table-body {
      overflow: auto !important;
      max-height: 500px;
    }
  }
 
 
}
.bm_propjet_details_table {
  .ant-table-cell {
    text-align: center;
  }
  .bm_project_dynamicFields {
    padding: 0.2rem 0.4rem;
    .ant-row {
      border: 1px solid #565656;
      margin: 0.5rem 0px;
      padding: 0.5rem 0px;
      padding-bottom: 0;
      .ant-col-12 {
        padding: 0.25rem;
        &:first-child {
          border-right: 1px solid #565656;
        }
      }
      .ant-col-24 {
        border-bottom: 1px solid #565656;
      }
    }
  }
}
