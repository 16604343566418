.activityProfileMng {
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin-top: -8px;
    .ant-table-title {
      // padding: 2px;
      // background: #eee;
      text-align: center;
    }
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    margin-bottom: 8px;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
  .ant-table-body {
    overflow-y: auto !important;
    max-height: 500px;
    //scrollbar-width: thin;
  }
  .ant-input-number-affix-wrapper {
    width: 100%;
  }
}
.tasks_dynamicFields {
  padding: 0.2rem 0.4rem;
  .ant-row {
    border: 1px solid #565656;
    margin: 0.5rem 0px;
    padding: 0.5rem 0px;
    padding-bottom: 0;
    .ant-col-12 {
      padding: 0.25rem;
      &:first-child {
        border-right: 1px solid #565656;
      }
    }
    .ant-col-24 {
      border-bottom: 1px solid #565656;
    }
  }
}
.activity_task_table{
  .general-model-component {
    .ant-btn-primary {
      margin-bottom: 0px;
    }
  }
}
